define("discourse/plugins/discourse-multilingual/discourse/templates/components/admin-translation", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="translation">
    <td class="locale">
      {{@translation.locale}}
    </td>
  
    <td class="types">
      {{@translation.file_type}}
    </td>
  
    <td class="actions">
      <DButton @action={{this.remove}}>
        {{#if removing}}
          {{loading-spinner size="small"}}
        {{else}}
          {{d-icon "xmark"}}
          {{i18n "multilingual.translations.remove"}}
        {{/if}}
      </DButton>
    </td>
  </tr>
  */
  {
    "id": "4xypf1Xh",
    "block": "[[[10,\"tr\"],[14,0,\"translation\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"locale\"],[12],[1,\"\\n    \"],[1,[30,1,[\"locale\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"types\"],[12],[1,\"\\n    \"],[1,[30,1,[\"file_type\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"actions\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@action\"],[[30,0,[\"remove\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"removing\"]],[[[1,\"        \"],[1,[28,[35,4],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,5],[\"xmark\"],null]],[1,\"\\n        \"],[1,[28,[35,6],[\"multilingual.translations.remove\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `removing` property path was used in the `discourse/plugins/discourse-multilingual/discourse/templates/components/admin-translation.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.removing}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@translation\"],false,[\"tr\",\"td\",\"d-button\",\"if\",\"loading-spinner\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/admin-translation.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});