define("discourse/plugins/discourse-multilingual/discourse/templates/components/multilingual-navigation", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="nav nav-pills">
    {{nav-item
      route="adminMultilingualLanguages"
      label="multilingual.languages.title"}}
    {{nav-item
      route="adminMultilingualTranslations"
      label="multilingual.translations.title"}}
    {{nav-item
      route="tagGroups"
      routeParam=contentLanguageTagGroupId
      label="multilingual.content_languages.tags.title"
      icon="tag"}}
  </ul>
  
  */
  {
    "id": "CE2C/tns",
    "block": "[[[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"adminMultilingualLanguages\",\"multilingual.languages.title\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"adminMultilingualTranslations\",\"multilingual.translations.title\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"route\",\"routeParam\",\"label\",\"icon\"],[\"tagGroups\",[30,0,[\"contentLanguageTagGroupId\"]],\"multilingual.content_languages.tags.title\",\"tag\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `contentLanguageTagGroupId` property path was used in the `discourse/plugins/discourse-multilingual/discourse/templates/components/multilingual-navigation.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.contentLanguageTagGroupId}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"ul\",\"nav-item\"]]",
    "moduleName": "discourse/plugins/discourse-multilingual/discourse/templates/components/multilingual-navigation.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});